import { extendTheme, Input, NumberInput, theme } from "@chakra-ui/react";

const breakpoints = {
  base: "0px",
  sm: "480px",
  md: "768px",
  lg: "900px",
  xl: "1025px",
  "2xl": "1400px",
};

Input.defaultProps = { ...Input.defaultProps, focusBorderColor: "green" };
NumberInput.defaultProps = {
  ...NumberInput.defaultProps,
  focusBorderColor: "green",
};

export const pharmacyAppTheme = extendTheme({
  // styles: {
  //   global: {
  //     body: {
  //       bg: "red.100",
  //       color: "blue.500",
  //     },s
  //   },
  // },
  styles: {
    global: {
      "html, body": {
        fontSize: ["12px", "12px", "11px", "12px", "14px", "15px"],
        // bg: "linear-gradient(90.14deg, #58b8b2 0.11%, rgba(88, 184, 152, 0.76375) 65.58%, rgba(88, 184, 138, 0.64) 99.87%)",
      },
    },
  },
  colors: {
    blueGreen: {
      100: "#F5FFFF",
      150: "#E2F6F6",
      200: "#7DF9FF",
      300: "#45A29C",
    },
    blue: {
      300: "#394D62",
      400: "#283645",
      450: "#13304F",
      500: "#04213F",
    },
    red: {
      300: "#F44B60",
      400: "#E72C43",
    },
    primaryFontColor: "#F5FFFF",
  },
  fonts: {
    body: "Prompt, sans-serif",
    heading: "Prompt, sans-serif",
    fontWeight: 400,
  },
  ...breakpoints,
  sizes: {
    ...theme.space,
    ...theme.sizes,
    container: {
      sm: breakpoints.md,
      md: breakpoints.lg,
      lg: breakpoints.xl,
      xl: breakpoints["2xl"],
    },
  },
  shadows: { outline: "0 0 0 3px #CF2714" },
  components: {
    Heading: {
      baseStyle: {
        color: "blue.500",
        fontSize: ["10px"],
      },
      sizes: {
        // https://www.figma.com/file/XxMd8O9XALgjuTeMPNkUtF/Air-Fryer-Scheduling?node-id=428%3A53094
        h1: {
          fontSize: "6rem",
          fontWeight: 700,
          lineHeight: "100%",
        },
        h2: {
          fontSize: "4.5rem",
          fontWeight: 700,
          lineHeight: "100%",
        },
        h2Thin: {
          fontSize: "4.5rem",
          fontWeight: 200,
          lineHeight: "100%",
        },
        h3: {
          fontSize: "3rem",
          fontWeight: 700,
          lineHeight: "130%",
        },
        h3Thin: {
          fontSize: "3rem",
          fontWeight: 200,
          lineHeight: "130%",
        },
        h4: {
          fontSize: "2.25rem",
          fontWeight: 500,
          lineHeight: "130%",
        },
        h5: {
          fontSize: "1.75rem",
          fontWeight: 500,
          lineHeight: "130%",
        },
        h6: {
          fontSize: "1.5rem",
          fontWeight: 400,
          lineHeight: "130%",
        },
        h7: {
          fontSize: "1.25rem",
          fontWeight: 400,
          lineHeight: "130%",
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: "1.15rem",
        fontWeight: 400,
        color: "blue.500",
        // color: "blueGreen.300"
      },
      // sizes: {
      //   subtitle: {
      //     fontSize: '16px',
      //     fontWeight: '600',
      //   },
      //   body1: {
      //     fontSize: '16px',
      //     fontWeight: '400',
      //   },
      //   body2: {
      //     fontSize: '16px',
      //     fontWeight: '600',
      //   },
      //   small: {
      //     fontSize: '14px',
      //     fontWeight: '400',
      //   },
      // },
    },
    MenuItem: {
      baseStyle: {
        color: "blue.500",
      },
    },
    Button: {
      baseStyle: {
        lineHeight: "1rem",
        borderRadius: "8px",
      },
      sizes: {
        md: {
          fontSize: "1.25rem",
          lineHeight: "1.25rem",
          height: "auto",
          px: "1.75rem",
          py: "0.75rem",
          fontWeight: 400,
        },
        // lg: {
        //   fontSize: "1.5rem",
        //   lineHeight: "1.5rem",
        //   height: "auto",
        //   px: "2rem",
        //   py: "1rem",
        //   fontWeight: 500,
        // },
        lg: {
          fontSize: "2rem",
          lineHeight: "2rem",
          height: "auto",
          px: "3rem",
          py: "1rem",
        },
      },
      variants: {
        primary: {
          color: "blueGreen.100",
          backgroundColor: "red.300",
          _hover: {
            backgroundColor: "red.400",
          },
          _active: {
            backgroundColor: "red.300",
          },
          border: "2px solid",
          borderColor: "red.300",
        },
        secondary: {
          color: "blue.400",
          border: "2px solid",
          borderColor: "blue.400",
          _hover: {
            backgroundColor: "rgba(40, 54, 69, 0.1)",
          },
          _active: {
            backgroundColor: "rgba(40, 54, 69, 0.2)",
          },
        },
        icon: {
          color: "blue.400",
          backgroundColor: "none",
          _hover: {
            color: "blueGreen.200",
          },
          _active: {
            color: "blueGreen.300",
          },
        },
        link: {
          color: "blue.500",
          backgroundColor: "none",
          fontWeight: "200",
          _hover: {
            color: "blueGreen.200",
          },
          _active: {
            color: "blueGreen.300",
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: "blue.400",
            borderRadius: "8px",
            _placeholder: {
              color: "gray.300",
            },
            _focus: {
              borderColor: "blue.500",
              boxShadow: "0 0 0 1px #273D52",
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: "red.400",
      },
    },
  },
  // components: {
  //   Button: {
  //     baseStyle: {
  //       borderRadius: "8px",
  //       fontWeight: 400,
  //     },
  //     variants: {
  //       primary: {
  //         color: "pink.100",
  //         backgroundColor: "blue.400",
  //         _hover: {
  //           backgroundColor: "blue.500",
  //         },
  //       },
  //       secondary: {
  //         color: "blue.400",
  //         backgroundColor: "blue.200",
  //         border: "1px",
  //         _hover: {
  //           backgroundColor: "blue.300",
  //         },
  //       },
  //       tertiary: {
  //         color: "blue.500",
  //         backgroundColor: "pink.300",
  //         _hover: {
  //           backgroundColor: "pink.400",
  //         },
  //       },
  //       links: {
  //         color: "orange.500",
  //         backgroundColor: "none",
  //         padding: "0px",
  //         _hover: {
  //           color: "orange.600",
  //         },
  //       },
  //       navLink: {
  //         color: "blue.500",
  //         backgroundColor: "none",
  //         padding: "0px",
  //         _hover: {
  //           color: "orange.500",
  //         },
  //       },
  //     },
  //     defaultProps: {
  //       variant: "primary",
  //     },
  //   },
  //   NumberInput: {
  //     variants: {
  //       outline: {
  //         field: {
  //           backgroundColor: "red.100",
  //           borderColor: "gray.200",
  //           borderRadius: "8px",
  //           _placeholder: {
  //             color: "gray.300",
  //           },
  //           _focus: {
  //             borderColor: "blue.500",
  //             outlineOffset: "2px",
  //             outline: "2px solid transparent",
  //             boxShadow: "0 0 0 1px #273D52",
  //           },
  //         },
  //       },
  //     },
  //   },
  //   Checkbox: {
  //     sizes: {
  //       md: {
  //         control: { width: "24px", height: "24px" },
  //       },
  //     },
  //     baseStyle: {
  //       control: {
  //         backgroundColor: "red.100",
  //         borderColor: "gray.200",
  //         borderRadius: "8px",
  //       },
  //     },
  //   },
  //   Textarea: {
  //     variants: {
  //       outline: {
  //         borderRaius: "8px",
  //         backgroundColor: "red.100",
  //       },
  //     },
  //   },
  //   Select: {
  //     variants: {
  //       outline: {
  //         field: {
  //           backgroundColor: "red.100",
  //           borderRadius: "8px",
  //           borderColor: "gray.200",
  //         },
  //       },
  //     },
  //   },
  // },
});
