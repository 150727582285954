import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { pharmacyAppTheme } from "./theme";
import { AppRoutes } from "./AppRoutes";
import { AuthProvider } from "./utils/AuthContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => (
  <BrowserRouter>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={pharmacyAppTheme}>
          <AppRoutes />
        </ChakraProvider>
      </QueryClientProvider>
    </AuthProvider>
  </BrowserRouter>
);
