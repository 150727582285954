import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuthContext } from "./utils";
import { ProtectedLayout } from "./components";
import { UnprotectedLayout } from "./components";

const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const Pricing = React.lazy(() => import("./pages/Pricing"));
const Signup = React.lazy(() => import("./pages/Signup"));
const CheckoutSuccess = React.lazy(() => import("./pages/CheckoutSuccess"));
const MyConsole = React.lazy(() => import("./pages/MyConsole"));
const Login = React.lazy(() => import("./pages/Login"));

export const AppRoutes = () => {
  const { isProvider } = useAuthContext();

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route element={<ProtectedLayout />}>
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/checkoutSuccess" element={<CheckoutSuccess />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<MyConsole />} />
          <Route
            path="*"
            element={<div>This is not the page you're looking for...</div>}
          />
        </Route>
        {/* <Route element={<ProtectedLayout />}>
          <Route path="/my-console" element={<MyConsole />} />
          <Route
            path="*"
            element={<div>This is not the page you're looking for...</div>}
          />
        </Route> */}
        {/* <Route path="/signup" element={<Signup />} /> */}
      </Routes>
    </React.Suspense>
  );
};
