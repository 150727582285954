import { Box, Flex, Heading, Text } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <Box backgroundColor="blueGreen.300" textAlign="center" pt={10} pb={6}>
      <Flex direction="column">
        <a href="/">
          <Heading
            fontFamily="Ranchers"
            size="h5"
            fontSize="2rem"
            position="relative"
            style={{ zIndex: 2 }}
            color="blueGreen.100"
          >
            Ratskeller
          </Heading>
        </a>
        <Text mt={2} color="blue.450" fontSize="12px">
          Copyright © 2023 Hunter Betz
        </Text>
      </Flex>
    </Box>
  );
};
