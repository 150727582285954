import axios from "axios";
import { createContext, useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../config";
import { useLocalStorage } from "../hooks";

type userIdAndIsProvider = {
  userId: number;
  isProvider: boolean;
};

export type AuthSettings = userIdAndIsProvider & {
  isAuthenticated: boolean;
  pharmacyId: number;
  login: ({ userId, isProvider }: userIdAndIsProvider) => void;
  logout: () => void;
};

// Using this context provider for very basic "authentication" and keeping track of the logged in userId
export const AuthContext = createContext<AuthSettings>({
  userId: 0,
  isProvider: false,
  isAuthenticated: false,
  pharmacyId: 0,
  login: () => null,
  logout: () => null,
});

export const AuthProvider = ({ children }: { children: any }) => {
  const [userId, setUserId] = useLocalStorage("userId", 0);
  const [pharmacyId, setPharmacyId] = useLocalStorage("pharmacyId", 0);
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    "isAuthenticated",
    false
  );
  const [isProvider, setIsProvider] = useLocalStorage("isProvider", false);
  const navigate = useNavigate();

  const login = useCallback(
    async ({ userId, isProvider }: userIdAndIsProvider) => {
      if (!isProvider) {
        const pharmacy = await axios.get(
          `${BASE_URL}/pharmacists/${userId}/pharmacy`
        );
        setPharmacyId(pharmacy.data[0].id);
      }
      setUserId(userId);
      setIsAuthenticated(true);
      setIsProvider(isProvider);
      const path = isProvider ? "/patients" : "/current-orders";
      navigate(path);
    },
    [setPharmacyId, setUserId, setIsAuthenticated, setIsProvider, navigate]
  );

  const logout = useCallback(() => {
    setUserId(0);
    setIsAuthenticated(false);
    setIsProvider(false);
    navigate("/");
  }, [setUserId, setIsAuthenticated, setIsProvider, navigate]);

  const value = useMemo(
    () => ({
      userId,
      isAuthenticated,
      isProvider,
      pharmacyId,
      login,
      logout,
    }),
    [userId, isProvider, isAuthenticated, pharmacyId, login, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
