import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

// Hiding the sliver of box-shadow that appears on the bottom of the .shadow box
export const FolderTabWrapper = styled(Box)`
  .shadow:after {
    background-color: #eeeaea;
    height: 4px;
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    z-index: 20;
    right: 0;
  }
`;
