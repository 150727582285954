import { DateTime, Info } from "luxon";

export const formatDOB = (date: string) =>
  DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("MM/dd/yyyy");

export const formatOrderDate = (date: string) =>
  date ? DateTime.fromJSDate(new Date(date)).toFormat("MM/dd/yyyy") : date;

// Returns an array of years in descending order starting from 120 years ago until the current year
const getYears = () => {
  const years = [];

  const dateStart = DateTime.now();
  let startYear = dateStart.year;

  const dateEnd = dateStart.minus({ years: 120 });
  const endYear = dateEnd.year;

  while (startYear >= endYear) {
    years.push(startYear);
    startYear--;
  }

  return years;
};

export const YEARS = getYears();

// An array of months in 2 digit format
export const MONTHS = Info.monthsFormat("2-digit");

// Returns an array of days from 1 to 31
const getDaysInAMonth = () => {
  const days = [];
  let startDay = 1;
  const endDay = 31;

  while (startDay <= endDay) {
    days.push(startDay);
    startDay++;
  }

  return days;
};

export const DAYS = getDaysInAMonth();
