import { DateTime, Info } from "luxon";
import { DAYS, MONTHS, YEARS } from "./utils";

export enum SEX {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export const SexTitleCaseMap = {
  [SEX.MALE]: "Male",
  [SEX.FEMALE]: "Female",
};

export enum STATUS {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  FILLED = "FILLED",
}

export const StatusTitleCaseMap = {
  [STATUS.PENDING]: "Pending",
  [STATUS.IN_PROGRESS]: "In Progress",
  [STATUS.FILLED]: "Filled",
};

export const yearsOptions = YEARS.map((year: number) => {
  const yearString = String(year);
  return {
    label: yearString,
    value: yearString,
  };
});

export const monthsOptions = MONTHS.map((month: string) => ({
  label: month,
  value: month,
}));

export const daysOptions = DAYS.map((day: number) => {
  const dayString = String(day);
  const formattedDay = dayString.length < 2 ? `0${dayString}` : dayString;

  return {
    label: formattedDay,
    value: formattedDay,
  };
});

export const sexOptions = [
  {
    label: SexTitleCaseMap[SEX.FEMALE],
    value: SEX.FEMALE,
  },
  {
    label: SexTitleCaseMap[SEX.MALE],
    value: SEX.MALE,
  },
];

export const statusOptions = [
  {
    label: StatusTitleCaseMap[STATUS.PENDING],
    value: STATUS.PENDING,
  },
  {
    label: StatusTitleCaseMap[STATUS.IN_PROGRESS],
    value: STATUS.IN_PROGRESS,
  },
  {
    label: StatusTitleCaseMap[STATUS.FILLED],
    value: STATUS.FILLED,
  },
];
