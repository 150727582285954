import { Box, Container, HStack } from "@chakra-ui/react";
import { Navigate, NavLink, Outlet, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { NavBar } from "./Navbar";
import { useAuthContext } from "../utils";
import { Footer } from "./Footer";

const NavWrapper = styled.div`
  .nav-link:hover {
    color: #f5ffff;
  }
  .nav-link:active {
    color: #7df9ff;
  }
`;

export const ProtectedLayout = () => {
  const { isAuthenticated } = useAuthContext();
  let location = useLocation();

  // if (!isAuthenticated) {
  //   return <Navigate to="/" />;
  // }

  return (
    <Box backgroundColor="gray.200">
      <Container
        width="100%"
        maxWidth="container.2xl"
        backgroundColor="blueGreen.100"
        padding="0px"
        display="flex"
        height="100vh"
        flexDirection="column"
      >
        <NavBar />
        <Box>
          <Box
            flex="1"
            overflow="auto"
            px={["30px", "50px", null, "92px"]}
            py="20px"
            pt="0"
          >
            <Outlet />
          </Box>
        </Box>
        <Footer />
      </Container>
    </Box>
  );
};
