import {
  Heading,
  Flex,
  useMediaQuery,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

const NavWrapper = styled.div`
  .nav-link:hover {
    color: #7df9ff;
  }
  .nav-link.active {
    color: #45a29c;
  }
`;

export const NavBar = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px");

  return (
    <Flex
      justify="space-between"
      paddingX={[6, 6, 14, 14]}
      paddingY={6}
      align="center"
    >
      <a href="/" className="landing-page-link">
        <Heading
          fontFamily="Ranchers"
          size="h5"
          fontSize="2rem"
          position="relative"
          style={{ zIndex: 2 }}
          color="red.300"
        >
          Ratskeller
        </Heading>
      </a>
      {isLargerThan768 ? (
        <NavWrapper style={{ zIndex: 2 }}>
          <HStack align="center" gap="2rem">
            <NavLink
              to="https://api.ratskellerrecipes.com/"
              className="nav-link"
            >
              API Docs
            </NavLink>
            <NavLink to="/pricing" className="nav-link">
              Pricing Plans
            </NavLink>
            <NavLink to="/dashboard" className="nav-link">
              My Console
            </NavLink>
          </HStack>
        </NavWrapper>
      ) : (
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            aria-label="Options"
            icon={<HamburgerIcon boxSize="26" padding="2px" />}
            _active={{ color: "#45a29c" }}
            _hover={{ color: "#7df9ff" }}
          ></MenuButton>
          <MenuList>
            <NavLink to="/docs" className="nav-link">
              <MenuItem>API Docs</MenuItem>
            </NavLink>
            <NavLink to="/pricing" className="nav-link">
              <MenuItem>Pricing Plans</MenuItem>
            </NavLink>
            <NavLink to="/dashboard" className="nav-link">
              <MenuItem>My Console</MenuItem>
            </NavLink>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};
